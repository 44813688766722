// Toolbar.js
import React, {
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faVolumeHigh,
  faVolumeXmark,
  faEye,
  faEyeSlash,
  faCheck,
  faRotateLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Toolbar.module.css";

const Toolbar = () => {
  const {
    currentWordInstances,
    selectedWordInstance,
    selectedFragment,
    getVocabularyByFragmentId,
    userProfile,
    translationVisible,
    handleSeeTranslationClick,
    onNextFragmentClick,
    onPrevFragmentClick,
    toggleMarkAsKnown,
    isSelectionCooldown,
    isToggleCooldown,
    knownCountUp,
    knownCountDown,
    setSelectedFragment,
  } = useContext(SentenceContext);

  const [isPlaying, setIsPlaying] = useState(false);

  // Create an AudioContext for Web Audio API playback.
  const audioContextRef = useRef(null);
  useEffect(() => {
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  // Ref to keep track of the currently playing TTS source.
  const ttsSourceRef = useRef(null);

  // Play TTS audio via Web Audio API.
  const playTTSAudio = useCallback(async (url) => {
    // Stop any currently playing TTS audio.
    if (ttsSourceRef.current) {
      try {
        ttsSourceRef.current.stop();
      } catch (e) {
        console.error("Error stopping previous TTS audio:", e);
      }
      ttsSourceRef.current = null;
      setIsPlaying(false);
    }
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start(0);
      setIsPlaying(true);
      source.onended = () => {
        setIsPlaying(false);
        ttsSourceRef.current = null;
      };
      ttsSourceRef.current = source;
    } catch (error) {
      console.error("Error playing TTS audio file:", error);
    }
  }, []);

  // Play sound effects (SFX) via Web Audio API without interrupting TTS audio.
  const playSFX = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContextRef.current.decodeAudioData(
        arrayBuffer
      );
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    } catch (error) {
      console.error("Error playing SFX audio file:", error);
    }
  }, []);

  const onFragmentTTSClick = useCallback(() => {
    const word = selectedWordInstance?.word?.text
      .toLowerCase()
      .replace(/[^a-záéíóúñ]/g, "");
    if (!word) return;
    const url = `/spanish-words/${word}.wav`;
    playTTSAudio(url);
  }, [selectedWordInstance, playTTSAudio]);

  // Auto-play TTS if enabled in user settings.
  useEffect(() => {
    if (userProfile?.settings?.autoPlayWordTTS) {
      onFragmentTTSClick();
    }
  }, [
    selectedWordInstance,
    onFragmentTTSClick,
    userProfile?.settings?.autoPlayWordTTS,
  ]);

  // Keyboard events for navigation and TTS.
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        event.stopPropagation();
        onPrevFragmentClick(currentWordInstances);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentWordInstances, onPrevFragmentClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
        event.stopPropagation();
        onFragmentTTSClick();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onFragmentTTSClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        onNextFragmentClick(currentWordInstances);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [currentWordInstances, onNextFragmentClick]);

  // Keyboard event for "k" to toggle fragment known state and play check mark sound.
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key.toLowerCase() === "k") {
        event.preventDefault();
        event.stopPropagation();
        if (selectedFragment) {
          toggleMarkAsKnown();
        }
        const vocab = getVocabularyByFragmentId(selectedFragment);
        if (vocab?.state !== "known") {
          playSFX("/sounds/chimes.wav");
        }
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [toggleMarkAsKnown, selectedFragment, getVocabularyByFragmentId, playSFX]);

  // Keyboard event for "t" to toggle translation visibility.
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key.toLowerCase() === "t") {
        event.preventDefault();
        event.stopPropagation();
        handleSeeTranslationClick();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleSeeTranslationClick]);

  const vocabulary = getVocabularyByFragmentId(selectedFragment);
  const isKnown = vocabulary?.state === "known";
  const [delayedIsKnown, setDelayedIsKnown] = useState(isKnown);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedIsKnown(isKnown);
    }, 10);
    return () => clearTimeout(timer);
  }, [isKnown]);

  const selectionText = () => {
    let selectionText = "--";
    if (selectedFragment) {
      const wordText = selectedWordInstance?.text;
      if (selectedWordInstance?.stem?._id === selectedFragment) {
        const stemText = selectedWordInstance?.stem?.text;
        selectionText = stemText;
        if (wordText.length > stemText.length) {
          selectionText = selectionText + "-";
        }
      } else {
        let endingText = "";
        if (selectedWordInstance?.ending1?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending1?.text;
        } else if (selectedWordInstance?.ending2?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending2?.text;
        } else if (selectedWordInstance?.ending3?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending3?.text;
        }
        selectionText = endingText;
        if (wordText && wordText.length > endingText.length) {
          selectionText = "-" + selectionText;
        }
      }
    }
    return selectionText;
  };

  const containerRef = useRef(null);

  let content = (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.positionToolbar}>
        <div className={styles.toolbarContainer}>
          <button
            onClick={() => onPrevFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Previous - Left Arrow Key"
            disabled={isToggleCooldown}
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
          </button>
          <button
            onClick={onFragmentTTSClick}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            } ${isPlaying ? styles.playing : ""}`}
            title={selectedFragment ? "Play Word TTS" : ""}
            disabled={isToggleCooldown}
          >
            <FontAwesomeIcon className={styles.icon} icon={faVolumeHigh} />
          </button>
          <button
            onClick={() => {
              if (vocabulary?.state !== "known" && selectedFragment) {
                playSFX("/sounds/chimes.wav");
              }
              if (selectedFragment) {
                toggleMarkAsKnown();
              }
              setSelectedFragment(null);
            }}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            }`}
            title={
              !selectedFragment
                ? ""
                : vocabulary?.state !== "known"
                ? 'Mark Fragment as Known - "K" Key'
                : 'Revert Fragment to Seen - "K" Key'
            }
          >
            <FontAwesomeIcon
              className={`${styles.check} ${
                !selectedFragment
                  ? styles.checkOff
                  : !delayedIsKnown
                  ? styles.glint
                  : styles.checkUndo
              }`}
              icon={faCheck}
            />
          </button>
          <button
            onClick={handleSeeTranslationClick}
            type="button"
            className={`${styles.button} ${
              !selectedFragment ? styles.buttonOff : ""
            }`}
            title={
              !selectedFragment
                ? ""
                : translationVisible[selectedWordInstance?._id]
                ? 'Hide Word Translation - "T" Key'
                : 'Show Word Translation - "T" Key'
            }
            disabled={isToggleCooldown}
          >
            <FontAwesomeIcon
              className={`${styles.icon} ${
                translationVisible[selectedWordInstance?._id]
                  ? styles.fadeOut
                  : styles.fadeIn
              } ${styles.eyeIcon}`}
              icon={faEye}
            />
            <FontAwesomeIcon
              className={`${styles.icon} ${
                translationVisible[selectedWordInstance?._id]
                  ? styles.fadeIn
                  : styles.fadeOut
              } ${styles.eyeSlashIcon}`}
              icon={faEyeSlash}
            />
          </button>
          <button
            onClick={() => onNextFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Next - Right Arrow Key"
            disabled={isToggleCooldown}
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Toolbar;
