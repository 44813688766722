import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectWordInstancesBySentenceId } from "../../wordInstancesApiSlice";
import { SentenceContext } from "../../SentenceContext/SentenceContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faX,
  faArrowDown,
  faLanguage,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Guide.module.css";
import { use } from "react";

// --------------------------------------------------------------------
// SlideTransition Component
// This component animates its children when the "transitionKey" changes.
const SlideTransition = ({
  children,
  transitionKey,
  direction = "normal",
  duration = 550,
}) => {
  const [currentKey, setCurrentKey] = useState(transitionKey);
  const [displayedChild, setDisplayedChild] = useState(children);
  const [animationClass, setAnimationClass] = useState(
    direction === "reverse" ? styles.slideInReverse : styles.slideIn
  );

  useEffect(() => {
    if (transitionKey !== currentKey) {
      const exitClass =
        direction === "reverse" ? styles.slideOutReverse : styles.slideOut;
      const enterClass =
        direction === "reverse" ? styles.slideInReverse : styles.slideIn;
      // Trigger exit animation:
      setAnimationClass(exitClass);
      const timeoutId = setTimeout(() => {
        // Update the content and trigger the entrance animation:
        setDisplayedChild(children);
        setCurrentKey(transitionKey);
        setAnimationClass(enterClass);
      }, duration);
      return () => clearTimeout(timeoutId);
    }
  }, [transitionKey, children, currentKey, duration, direction]);

  return (
    <div
      className={animationClass}
      style={{ animationDuration: `${duration}ms` }}
    >
      {displayedChild}
    </div>
  );
};

// --------------------------------------------------------------------
// Guide Component
const Guide = () => {
  const {
    bookId,
    chapter,
    selectedFragment,
    getVocabularyByFragmentId,
    currentSentenceId,
    transitionTime,
    showTranslationSection,
    setActiveFragmentId,
    isLevelUp,
    isHideGuide,
    setIsHideGuide,
    indexAnimateDirection,
    setGuideBaseKey,
    setSelectedFragment,
    skippedFragmentIds,
    setSkippedFragmentIds,
    showGrammarSection,
  } = useContext(SentenceContext);

  // Use currentSentenceId directly to get the word instances for the current sentence.
  const displayedWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
  );

  const [showClickCheck, setShowClickCheck] = useState(false);
  const [interactionText, setInteractionText] = useState("Click");
  const [keyStrokeText, setKeyStrokeText] = useState("");

  // --------------------------------------------------------------------
  // Build an unfiltered list of all fragments from displayedWordInstances.
  const allFragments = useMemo(() => {
    const fragments = [];
    for (const wordInstance of displayedWordInstances) {
      let mutableText = wordInstance.text;
      const fragmentCandidates = [
        wordInstance.stem,
        wordInstance.ending1,
        wordInstance.ending2,
        wordInstance.ending3,
      ].filter(Boolean);

      for (let i = 0; i < fragmentCandidates.length; i++) {
        const fragment = fragmentCandidates[i];
        const textLength = fragment.text.length;
        const textSlice = mutableText.slice(0, textLength);
        mutableText = mutableText.slice(textLength);
        fragments.push({
          id: fragment._id,
          text: textSlice,
          type: i === 0 ? "stem" : `ending${i}`,
          state: getVocabularyByFragmentId(fragment._id)?.state || "unknown",
        });
      }
    }
    return fragments;
  }, [displayedWordInstances, getVocabularyByFragmentId]);

  // --------------------------------------------------------------------
  // Determine the active fragment:
  // The first fragment that is not skipped and whose vocabulary state is not "known."
  const activeFragment = useMemo(() => {
    return (
      allFragments.find(
        (fragment) =>
          !skippedFragmentIds.includes(fragment.id) &&
          fragment.state !== "known"
      ) || null
    );
  }, [allFragments, skippedFragmentIds]);

  // Calculate the index of the active fragment.
  const currentActiveFragmentIndex = activeFragment
    ? allFragments.findIndex((fragment) => fragment.id === activeFragment.id)
    : -1;

  // --------------------------------------------------------------------
  // Update activeFragmentId in context.
  useEffect(() => {
    if (activeFragment && activeFragment.id !== selectedFragment) {
      setActiveFragmentId(activeFragment.id);
    } else {
      setActiveFragmentId(null);
    }
  }, [activeFragment, selectedFragment, setActiveFragmentId]);

  const prevActiveFragmentIdRef = useRef(activeFragment?.id);

  useEffect(() => {
    if (activeFragment?.id !== prevActiveFragmentIdRef.current) {
      setShowClickCheck(false);
      prevActiveFragmentIdRef.current = activeFragment?.id;
    }
  }, [activeFragment]);

  const isFirstNonKnownSelected =
    activeFragment && activeFragment.id === selectedFragment;

  // When the X is clicked, skip the active fragment.
  const handleSkip = () => {
    if (activeFragment) {
      setSkippedFragmentIds((prev) => [...prev, activeFragment.id]);
    }
  };

  // --------------------------------------------------------------------
  // Dashes for display formatting.
  const trailingDash =
    activeFragment?.type === "stem" &&
    displayedWordInstances.some(
      (wordInstance) =>
        wordInstance.stem?._id === activeFragment.id &&
        (wordInstance.ending1 || wordInstance.ending2 || wordInstance.ending3)
    )
      ? "-"
      : "";
  const leadingDash = activeFragment?.type?.includes("ending") ? "-" : "";

  const checkText = activeFragment?.type?.includes("ending")
    ? "Check the attributes below."
    : "Look at the translation.";

  const noSelectionText = !selectedFragment ? (
    <>
      {" "}
      or <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
    </>
  ) : null;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(pointer: coarse)");
    const updateText = (e) => {
      setInteractionText(e.matches ? "Tap" : "Click");
      setKeyStrokeText(e.matches ? "" : ", or press 'k'.");
    };

    // Set initial state
    updateText(mediaQuery);

    // Listen for changes
    mediaQuery.addEventListener("change", updateText);
    return () => mediaQuery.removeEventListener("change", updateText);
  }, []);

  // --------------------------------------------------------------------
  // Build the animated content and determine a base key for transitions.
  let baseKey = "";
  let animatedContent;

  if (allFragments.length === 0) {
    animatedContent = <div></div>;
  } else if (isLevelUp) {
    baseKey = "levelUp";
    animatedContent = (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <span>Congratulations!🍾</span>
        </div>
      </div>
    );
  } else if (!activeFragment) {
    baseKey = showTranslationSection ? "translation" : "tapLanguage";
    animatedContent = !showTranslationSection ? (
      <div>
        <span>
          {interactionText}{" "}
          <FontAwesomeIcon className={styles.language} icon={faLanguage} />
        </span>
      </div>
    ) : (
      <>
        <div>
          <span>Compare the sentences.</span>
        </div>
        <div>
          <span>
            {interactionText}{" "}
            <FontAwesomeIcon
              className={styles.downChevron}
              icon={faChevronDown}
            />{" "}
            above to go to the next sentence.
          </span>
        </div>
      </>
    );
  } else if (!isFirstNonKnownSelected) {
    baseKey = selectedFragment
      ? `tap-${activeFragment.id}`
      : `tap2-${activeFragment.id}`;
    animatedContent = selectedFragment ? (
      <div>
        <span>{interactionText}&nbsp;</span>
        <span
          className={`${activeFragment?.type} ${activeFragment?.state}`}
          style={{ fontFamily: "var(--TARGET-FF)" }}
        >
          {leadingDash}
          {activeFragment?.text}
          {trailingDash}
        </span>
      </div>
    ) : (
      <div>
        <span>{interactionText}&nbsp;</span>
        <span
          className={`${activeFragment?.type} ${activeFragment?.state}`}
          style={{ fontFamily: "var(--TARGET-FF)" }}
        >
          {leadingDash}
          {activeFragment?.text}
          {trailingDash}
        </span>
        <span> {noSelectionText}</span>
      </div>
    );
  } else if (!showClickCheck) {
    baseKey = !activeFragment?.type?.includes("ending")
      ? `ask-${activeFragment.id}`
      : showGrammarSection
      ? `askEnding-${activeFragment.id}`
      : `tapBook-${activeFragment.id}`;
    animatedContent = activeFragment?.type?.includes("ending") ? (
      showGrammarSection ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: ".5rem",
          }}
        >
          <div>
            <span>{checkText}</span>
          </div>
          <div>
            <span>Do you know&nbsp;</span>
            <span
              className={`${activeFragment?.type} ${activeFragment?.state}`}
              style={{ fontFamily: "var(--TARGET-FF)" }}
            >
              {leadingDash}
              {activeFragment?.text}
              {trailingDash}
            </span>
            <span>?</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                // marginTop: ".5rem",
                maxWidth: "400px",
                gap: "50px",
              }}
            >
              <div>
                <FontAwesomeIcon
                  className={styles.check}
                  icon={faCheck}
                  onClick={() => setShowClickCheck(true)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  className={styles.x}
                  icon={faX}
                  onClick={() => {
                    handleSkip();
                    setSelectedFragment(null);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span style={{ display: "flex", justifyContent: "center" }}>
            {interactionText}{" "}
            <div className={styles.headerToggle__grammar}>
              <div style={{ position: "relative" }}>
                <div className={styles.grammarOnBackground}></div>
                <div className={styles.sentenceButtonOn}>
                  <FontAwesomeIcon icon={faBookOpen} />
                </div>
              </div>
            </div>
          </span>
        </div>
      )
    ) : (
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: ".5rem" }}
      >
        <div>
          <span>{checkText}</span>
        </div>
        <div>
          <span>Do you know&nbsp;</span>
          <span
            className={`${activeFragment?.type} ${activeFragment?.state}`}
            style={{ fontFamily: "var(--TARGET-FF)" }}
          >
            {leadingDash}
            {activeFragment?.text}
            {trailingDash}
          </span>
          <span>?</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              // marginTop: ".5rem",
              maxWidth: "400px",
              gap: "50px",
            }}
          >
            <div>
              <FontAwesomeIcon
                className={styles.check}
                icon={faCheck}
                onClick={() => setShowClickCheck(true)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                className={styles.x}
                icon={faX}
                onClick={() => {
                  handleSkip();
                  setSelectedFragment(null);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    baseKey = `tapCheck-${activeFragment.id}`;
    animatedContent = (
      <>
        <FontAwesomeIcon
          style={{
            position: "absolute",
            left: "0%",
            top: "15%",
            fontSize: "1rem",
            color: "var(--BORDER-COLOR)",
            padding: "1rem",
          }}
          icon={faChevronLeft}
          onClick={() => setShowClickCheck(false)}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <span>
              {interactionText} the check mark.{keyStrokeText}
            </span>
          </div>
          <div>
            <FontAwesomeIcon className={styles.downArrow} icon={faArrowDown} />
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    setGuideBaseKey(baseKey);
  }, [baseKey, setGuideBaseKey]);

  // Always force a new transition when the sentence changes by including currentSentenceId in the key.
  const contentKey = `${currentSentenceId}-${baseKey}-${showClickCheck}`;
  // --------------------------------------------------------------------
  // Calculate the transition direction.
  // When the sentence changes, ignore in-sentence logic and use the overall direction.
  // For same-sentence transitions, apply in-sentence rules:
  // - If transitioning from "ask-" to "tap-" for the same fragment, use reverse.
  // - Or, if the active fragment index decreases, use reverse.
  const [transitionDirection, setTransitionDirection] = useState("normal");
  const prevSentenceIdRef = useRef(currentSentenceId);
  const prevBaseKeyRef = useRef(baseKey);
  const prevActiveFragmentIndexRef = useRef(currentActiveFragmentIndex);

  useEffect(() => {
    let newDirection = "normal";
    console.log(prevBaseKeyRef.current, baseKey);
    if (prevSentenceIdRef.current !== currentSentenceId) {
      // Sentence has changed – use overall direction.
      newDirection = indexAnimateDirection === "up" ? "normal" : "reverse";
    } else {
      // Same sentence: apply in-sentence rules.
      if (
        (prevBaseKeyRef.current.startsWith("ask-") &&
          baseKey.startsWith("tap-")) ||
        (prevBaseKeyRef.current.startsWith("ask-") &&
          baseKey.startsWith("tap2-")) ||
        (prevBaseKeyRef.current.startsWith("translation") &&
          baseKey.startsWith("tapLanguage")) ||
        (prevBaseKeyRef.current.startsWith("tapCheck") &&
          baseKey.startsWith("tap-")) ||
        (prevBaseKeyRef.current.startsWith("tapCheck") &&
          baseKey.startsWith("tap2-")) ||
        (prevBaseKeyRef.current.startsWith("tapCheck") &&
          baseKey.startsWith("ask-")) ||
        (prevBaseKeyRef.current.startsWith("askEnding-") &&
          baseKey.startsWith("tapBook-")) ||
        (prevBaseKeyRef.current.startsWith("tapCheck") &&
          baseKey.startsWith("askEnding-")) ||
        (prevBaseKeyRef.current.startsWith("tapBook-") &&
          baseKey.startsWith("tap-"))
      ) {
        // If transitioning from ask- to tap- for the same fragment, use reverse.
        const prevFragmentId = prevBaseKeyRef.current.split("-")[1];
        const currFragmentId = baseKey.split("-")[1];
        if (
          prevFragmentId === currFragmentId
          //   ||
          //   prevBaseKeyRef.current.startsWith("tapCheck")
        ) {
          newDirection = "reverse";
        }
      } else {
        // If active fragment index decreased, use reverse.
        newDirection = "normal";
      }
    }
    setTransitionDirection(newDirection);
    prevSentenceIdRef.current = currentSentenceId;
    prevBaseKeyRef.current = baseKey;
    prevActiveFragmentIndexRef.current = currentActiveFragmentIndex;
  }, [
    baseKey,
    currentSentenceId,
    currentActiveFragmentIndex,
    indexAnimateDirection,
  ]);

  // --------------------------------------------------------------------
  // Render the Guide with the SlideTransition component.
  return (
    <div
      className={`${styles.container} ${isHideGuide ? styles.hideGuide : ""}`}
    >
      <div
        className={`${styles.guideAlign} ${
          isHideGuide ? styles.guideAlignHide : ""
        }`}
        style={{ transitionDelay: `${isHideGuide ? "0ms" : "250ms"}` }}
      >
        <SlideTransition
          transitionKey={contentKey}
          direction={transitionDirection}
          duration={transitionTime}
        >
          {animatedContent}
        </SlideTransition>
      </div>
      <div
        className={styles.hideText}
        style={{
          opacity: !isHideGuide && showTranslationSection ? 1 : 0,
        }}
      >
        ({interactionText} the bar to hide)
      </div>
      <div
        className={styles.hide}
        style={{
          transform: !isHideGuide ? "translateY(-15px)" : "translateY(-33px)",
        }}
        onClick={() => setIsHideGuide(!isHideGuide)}
      >
        —
      </div>
    </div>
  );
};

export default Guide;
