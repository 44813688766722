import React, {
  useEffect,
  useCallback,
  useRef,
  useContext,
  useState,
} from "react";
import MorphologyContent from "./MorphologyContent/MorphologyContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faLanguage,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Info.module.css";

const Info = () => {
  const {
    currentWordInstances,
    selectedWord,
    selectedFragment,
    getVocabularyByFragmentId,
    userProfile,
    bookTitle,
    currentSentence,
    currentSentenceId,
    prevSentenceId,
    showTranslationSection,
    setShowTranslationSection,
    showGrammarSection,
    setShowGrammarSection,
    isTransitioning,
    transitionTime,
    guideBaseKey,
  } = useContext(SentenceContext);

  const transitionTimeDecimal = transitionTime / 1000;

  const [prevSentence, setPrevSentence] = useState(null);
  const [delayedSentence, setDelayedSentence] = useState(null);

  useEffect(() => {
    if (prevSentence !== currentSentence) {
      setTimeout(() => {
        setDelayedSentence(currentSentence);
      }, transitionTime);
      setPrevSentence(currentSentence);
    }
  }, [prevSentence, currentSentence, transitionTime]);

  // Create a ref for the translationContent container
  const translationContentRef = useRef(null);

  // Scroll the translation container to the top when the sentence changes
  useEffect(() => {
    if (translationContentRef.current) {
      translationContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
      // For an instant jump, you could use:
      // translationContentRef.current.scrollTop = 0;
    }
  }, [delayedSentence]);

  const grammarContentRef = useRef(null);

  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  if (!audioRef.current) {
    audioRef.current = new Audio();
  }

  const onSentenceTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const bookSrc = bookTitle.toLowerCase().replace(/[ ]/g, "-");
    const sentenceSrc = `${currentSentence.part}-${currentSentence.chapter}-${currentSentence.section}-${currentSentence.position}`;
    audioRef.current.src = `/${bookSrc}/${sentenceSrc}.wav`;

    console.log(audioRef.current.src);

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [bookTitle, currentSentence]);

  useEffect(() => {
    if (userProfile?.settings?.autoPlaySentenceTTS) {
      onSentenceTTSClick();
    }
  }, [
    currentSentenceId,
    onSentenceTTSClick,
    userProfile?.settings?.autoPlaySentenceTTS,
  ]);

  useEffect(() => {
    if (isTransitioning && grammarContentRef.current) {
      const scrollDuration = 1000;
      const startPosition = grammarContentRef.current.scrollTop;
      const startTime = performance.now();

      const scroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / scrollDuration, 1);

        // Easing function for smoother motion
        const easeOutCubic = 1 - Math.pow(1 - progress, 3);

        grammarContentRef.current.scrollTop =
          startPosition * (1 - easeOutCubic);

        if (progress < 1) {
          requestAnimationFrame(scroll);
        }
      };

      requestAnimationFrame(scroll);
    }
  }, [isTransitioning]);

  useEffect(() => {
    if (selectedFragment === null) {
      setShowGrammarSection(false);
    }
  }, [selectedFragment]);

  useEffect(() => {
    setShowGrammarSection(false);
  }, [selectedWordInstance]);

  return (
    <div className={styles.gridInfo}>
      <div className={styles.gridInfo_content}>
        {/* left side */}
        <div className={styles.translationCol}>
          <div
            className={`${styles.header} ${
              showTranslationSection ? "" : `${styles.header__off}`
            }`}
          >
            <div style={{ width: "40px" }}></div>
            <div className={styles.translationTitle}>sentence</div>
            <div
              className={`
              ${styles.headerToggle__sentence} 
              ${guideBaseKey === "tapLanguage" ? styles.shake : ""}
            `}
              onClick={() => setShowTranslationSection(!showTranslationSection)}
            >
              <div style={{ position: "relative" }}>
                <div
                  className={
                    showTranslationSection
                      ? styles.languageOnBackground
                      : styles.languageOffBackground
                  }
                >
                  {" "}
                  <div
                    className={
                      showTranslationSection
                        ? styles.sentenceButtonOn
                        : styles.sentenceButtonOff
                    }
                  >
                    <FontAwesomeIcon
                      icon={faLanguage}
                      title={
                        !showTranslationSection
                          ? "Show Sentence Translation"
                          : "Hide Sentence Translation"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Attach the ref here */}
          <div
            className={styles.translationContent}
            ref={translationContentRef}
          >
            <div
              className={`${styles.sentenceTranslation} ${
                showTranslationSection ? styles.revealed : styles.hidden
              }`}
            >
              {delayedSentence?.translation?.split(" ")?.map((word, index) => (
                <div key={index}>
                  <div
                    className={styles.fadeInWord}
                    style={{
                      transitionDelay: showTranslationSection
                        ? `${transitionTimeDecimal + index * 0.04}s`
                        : "0s",
                    }}
                  >
                    {word}{" "}
                  </div>
                </div>
              ))}
              <span className={styles.spacer}></span>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className={styles.grammarCol}>
          <div
            className={`${styles.header} ${
              showGrammarSection ? "" : `${styles.header__off}`
            }`}
          >
            <div
              className={`${styles.headerToggle__grammar} ${
                guideBaseKey.startsWith("tapBook") ? styles.shake : ""
              }`}
              onClick={() => setShowGrammarSection(!showGrammarSection)}
            >
              <div style={{ position: "relative" }}>
                <div
                  className={
                    !showGrammarSection
                      ? styles.grammarOnBackground
                      : styles.grammarOffBackground
                  }
                >
                  {" "}
                  <div
                    className={
                      !showGrammarSection
                        ? styles.sentenceButtonOn
                        : styles.sentenceButtonOff
                    }
                    style={{ marginTop: "1px" }}
                  >
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      title={
                        !showGrammarSection
                          ? "Show Word Info"
                          : "Hide Word Info"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grammarTitle}>word</div>
            <div style={{ width: "40px" }}></div>
          </div>
          <div className={styles.grammarContent} ref={grammarContentRef}>
            <MorphologyContent
              showGrammarSection={showGrammarSection}
              selectedWordInstance={selectedWordInstance}
              getVocabularyByFragmentId={getVocabularyByFragmentId}
              selectedFragment={selectedFragment}
              grammarContentRef={grammarContentRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
