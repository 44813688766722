// WordInstance.jsx
import React, { memo, useEffect, useRef, useState, useContext } from "react";
import { SentenceContext } from "../SentenceContext/SentenceContext";
import styles from "./Target.module.css";

const WordInstance = ({
  wordInstanceId,
  translation,
  leadingPunctuation,
  trailingPunctuation,
  isSelectedWord,
  slices,
  modernEquivalent,
  olLeftOffset,
  olRightOffset,
  indent,
  transitionStage,
  hasNoNewFragments,
  hasNoSeenFragments,
  hasNoKnownFragments,
}) => {
  const {
    translationVisible,
    handleFragmentSelection,
    isSelectionCooldown,
    isToggleCooldown,
    selectedFragment,
    activeFragmentId,
    isHideGuide,
  } = useContext(SentenceContext);

  const [alignmentClass, setAlignmentClass] = useState("");
  const tableRef = useRef(null);
  const [hasFadedIn, setHasFadedIn] = useState(false);

  useEffect(() => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const threshold = 10;
      const touchesLeft = rect.left <= olLeftOffset + threshold;
      const touchesRight = rect.right >= olRightOffset - threshold;

      if (touchesLeft) {
        setAlignmentClass(styles.alignLeft);
      } else if (touchesRight) {
        setAlignmentClass(styles.alignRight);
      } else {
        setAlignmentClass("");
      }
    }
  }, [olLeftOffset, olRightOffset]);

  const handleAnimationEnd = () => {
    setHasFadedIn(true);
  };

  return (
    <li
      key={wordInstanceId}
      className={indent ? styles.paragraphIndentation : ""}
    >
      <table ref={tableRef}>
        <tbody>
          {/* Translation row */}
          <tr
            className={`${styles.translation} ${
              translationVisible[wordInstanceId] ? styles.visible : ""
            }`}
          >
            {leadingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              ></td>
            )}
            <td>{translation}</td>
            {trailingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              ></td>
            )}
          </tr>

          {/* Word row */}
          <tr id={wordInstanceId} className={styles.wordInstance}>
            {leadingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              >
                {leadingPunctuation}
              </td>
            )}
            <td className={`${styles.wordInstanceText} ${alignmentClass}`}>
              {slices.map((slice) => {
                const isDisabled = isSelectionCooldown || isToggleCooldown;
                const shouldHop =
                  transitionStage === "rest" &&
                  activeFragmentId === slice.fragmentId &&
                  !isHideGuide;

                // If hopping, simply randomize the animation delay (the “space in time” before the hop).
                const randomDelay = shouldHop
                  ? `${Math.random() * 2 + 0.5}s`
                  : "0s";

                return (
                  <span
                    onClick={() => {
                      if (
                        slice.isSelectedFragment ||
                        (!isSelectionCooldown && !isToggleCooldown)
                      ) {
                        handleFragmentSelection(
                          wordInstanceId,
                          slice.fragmentId,
                          slice.vocabulary
                        );
                      }
                    }}
                    id={slice.fragmentId}
                    key={slice.fragmentId}
                    className={`${styles.fragment}
                      ${isSelectedWord ? styles.selectedWord : ""}
                      ${slice.isSelectedFragment ? styles.selectedFragment : ""}
                      ${slice.type}
                      ${slice.vocabulary?.state || "new"}
                      ${
                        shouldHop
                          ? styles.hop
                          : transitionStage === "fadeIn"
                          ? styles.animate
                          : ""
                      }
                    `}
                    style={{
                      animationDelay: randomDelay,
                      animationFillMode: "forwards",
                      opacity: shouldHop
                        ? 1
                        : transitionStage === "fadeIn"
                        ? 0
                        : 1,
                      cursor:
                        !slice.isSelectedFragment &&
                        (isSelectionCooldown || isToggleCooldown)
                          ? "not-allowed"
                          : "pointer",
                      pointerEvents:
                        !slice.isSelectedFragment &&
                        (isSelectionCooldown || isToggleCooldown)
                          ? "none"
                          : "auto",
                      "--fragment-content": `"${slice.text}"`,
                    }}
                    onAnimationEnd={handleAnimationEnd}
                  >
                    {slice.text}
                  </span>
                );
              })}
            </td>
            {trailingPunctuation && (
              <td
                className={`${styles.punctuation} ${
                  transitionStage === "fadeIn" ? styles.animate : ""
                }`}
                style={{
                  animationFillMode: "forwards",
                  opacity: transitionStage === "fadeIn" ? 0 : 1,
                }}
              >
                {trailingPunctuation}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </li>
  );
};

export default memo(WordInstance);
