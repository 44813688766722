// import React, { useContext } from "react";
import {
  LANGUAGE_VALUE_DESCRIPTIONS,
  POS_VALUE_DESCRIPTIONS,
  DETERMINER_TYPE_VALUE_DESCRIPTIONS,
  PRONOUN_TYPE_VALUE_DESCRIPTIONS,
  PRONOUN_FUNCTION_VALUE_DESCRIPTIONS,
  VERB_TYPE_VALUE_DESCRIPTIONS,
  FORM_VALUE_DESCRIPTIONS,
  MOOD_VALUE_DESCRIPTIONS,
  TENSE_VALUE_DESCRIPTIONS,
  PERSON_VALUE_DESCRIPTIONS,
  GENDER_VALUE_DESCRIPTIONS,
  NUMBER_VALUE_DESCRIPTIONS,
  CLITIC_FUNCTION_VALUE_DESCRIPTIONS,
  CLITIC_PERSON_VALUE_DESCRIPTIONS,
  CLITIC_GENDER_VALUE_DESCRIPTIONS,
  CLITIC_NUMBER_VALUE_DESCRIPTIONS,
} from "./attributeValueDescriptions";
import toCamelCase from "../../../../utils/toCamelCase";
import { toTextualPerson } from "../../../../utils/toTextualPerson";
import { contributingFragment } from "./contributingFragment";

// import { SentenceContext } from "../../SentenceContext/SentenceContext";

export const ATTRIBUTE_LIST = (
  selectedWordInstance,
  getVocabularyByFragmentId,
  selectedFragment
) => {
  // todo: fix below
  let selectedWord = selectedWordInstance;
  // todo: use context
  // const {
  // targetSentenceRef,
  // currentWordInstances,
  // beginsParagraph,
  // selectedWord,
  // selectedFragment,
  // translationVisible,
  // handleFragmentSelection,
  // getVocabularyByFragmentId,
  // userProfile,
  // knownCount,
  // bookTitle,
  // currentSentence,
  // totalSentences,
  // currentSentenceId,
  // currentSentenceIndex,
  // onNextSentenceClick,
  // onPrevSentenceClick,
  // showTranslationSection,
  // setShowTranslationSection,
  // showGrammarSection,
  // setShowGrammarSection,
  // } = useContext(SentenceContext);

  if (selectedWord && selectedFragment) {
    // get vocabulary state of each fragment
    const stemVocabularyState = selectedWord?.stem?._id
      ? getVocabularyByFragmentId(selectedWord?.stem?._id)?.state
      : null;

    const ending1VocabularyState = selectedWord?.ending1?._id
      ? getVocabularyByFragmentId(selectedWord?.ending1?._id)?.state
      : null;

    const ending2VocabularyState = selectedWord?.ending2?._id
      ? getVocabularyByFragmentId(selectedWord?.ending2?._id)?.state
      : null;

    const ending3VocabularyState = selectedWord?.ending3?._id
      ? getVocabularyByFragmentId(selectedWord?.ending3?._id)?.state
      : null;

    // create obj for easy fragment vocabulary state access
    const FRAGMENT_VOCABULARY_STATES = {
      stem: stemVocabularyState,
      ending1: ending1VocabularyState,
      ending2: ending2VocabularyState,
      ending3: ending3VocabularyState,
    };

    // create obj for east fragment _id access
    const FRAGMENT_IDS = {
      stem: selectedWord?.stem?._id,
      ending1: selectedWord?.ending1?._id,
      ending2: selectedWord?.ending2?._id,
      ending3: selectedWord?.ending3?._id,
    };

    // format contraction
    const contractionString = selectedWord?.word?.contraction?.replace(
      /,/g,
      ", "
    );

    // format pos
    const posArr = selectedWord?.word?.pos?.split(",");
    // console.log(`selectedWord: ${selectedWord}`);
    // console.log(`selectedWord.word: ${selectedWord.word}`);
    // console.log(`posArr: ${posArr}`);
    // console.log(`posArr[0]: ${posArr[0]}`);

    const posString = toCamelCase(posArr[0]);

    // determine which fragment contributes which attributes and the vocabulary state of each
    // form
    const formContributingFragment = contributingFragment(selectedWord, "form");
    const formColor = FRAGMENT_VOCABULARY_STATES[formContributingFragment];

    // mood
    const moodContributingFragment = contributingFragment(selectedWord, "mood");
    const moodColor = FRAGMENT_VOCABULARY_STATES[moodContributingFragment];

    // tense
    const tenseContributingFragment = contributingFragment(
      selectedWord,
      "tense"
    );
    const tenseColor = FRAGMENT_VOCABULARY_STATES[tenseContributingFragment];

    // person
    const personContributingFragment = contributingFragment(
      selectedWord,
      "person"
    );
    const personColor = FRAGMENT_VOCABULARY_STATES[personContributingFragment];

    // gender
    const genderContributingFragment = contributingFragment(
      selectedWord,
      "gender"
    );
    const genderColor = FRAGMENT_VOCABULARY_STATES[genderContributingFragment];

    // number
    const numberContributingFragment = contributingFragment(
      selectedWord,
      "number"
    );
    const numberColor = FRAGMENT_VOCABULARY_STATES[numberContributingFragment];

    // determine which fragment contributes which clitic attributes
    let clitic1ContributingFragment = null;
    let clitic1Color = null;

    let clitic2ContributingFragment = null;
    let clitic2Color = null;

    // create array of fragments
    let clitics = [
      { stem: selectedWord?.stem },
      { ending1: selectedWord?.ending1 },
      { ending2: selectedWord?.ending2 },
      { ending3: selectedWord?.ending3 },
    ]
      .filter((obj) => {
        return Object.values(obj)[0] !== null; // first filter out null values
      })
      .filter((obj) => {
        return Object.values(obj)[0].clitic === true; // then filter out non clitics
      });

    // assign first clitic function, person, gender, number to first fragment that is a clitic & has matching attributes
    if (
      clitics.length > 0 &&
      (selectedWord?.clitic1Function ||
        selectedWord?.clitic1Person ||
        selectedWord?.clitic1Gender ||
        selectedWord?.clitic1Number)
    ) {
      // check that every attribute matches exactly
      if (
        Object.values(clitics[0])[0].cliticFunction ===
          selectedWord?.clitic1Function &&
        Object.values(clitics[0])[0].cliticPerson ===
          selectedWord?.clitic1Person &&
        Object.values(clitics[0])[0].cliticGender ===
          selectedWord?.clitic1Gender &&
        Object.values(clitics[0])[0].cliticNumber ===
          selectedWord?.clitic1Number
      ) {
        // assign
        clitic1ContributingFragment = Object.keys(clitics[0])[0];
        clitic1Color = FRAGMENT_VOCABULARY_STATES[clitic1ContributingFragment];
        // remove clitic from clitics array
        clitics.shift();
      }
    }

    // assign second clitic function, person, gender, number to first fragment that is a clitic & has matching attributes
    if (
      clitics.length > 0 &&
      (selectedWord?.clitic2Function ||
        selectedWord?.clitic2Person ||
        selectedWord?.clitic2Gender ||
        selectedWord?.clitic2Number)
    ) {
      // check that every attribute matches exactly
      if (
        Object.values(clitics[0])[0].cliticFunction ===
          selectedWord?.clitic2Function &&
        Object.values(clitics[0])[0].cliticPerson ===
          selectedWord?.clitic2Person &&
        Object.values(clitics[0])[0].cliticGender ===
          selectedWord?.clitic2Gender &&
        Object.values(clitics[0])[0].cliticNumber ===
          selectedWord?.clitic2Number
      ) {
        // assign
        clitic2ContributingFragment = Object.keys(clitics[0])[0];
        clitic2Color = FRAGMENT_VOCABULARY_STATES[clitic2ContributingFragment];
      }
    }

    // build list
    if (selectedWord?.word?.language?.name !== "Spanish") {
      return [
        {
          attributeName: "language",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue: selectedWord?.word?.language?.name,
          attributeNameDescription:
            "specifies the system of communication to which the word belongs",
          attributeValueDescription:
            LANGUAGE_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.word?.language?.name)
            ],
          selected: false,
        },
        {
          attributeName: "part of speech",
          attributeColor: `stem ${stemVocabularyState}`,
          attributeValue: posArr,
          attributeNameDescription: "indicates a word's function",
          attributeValueDescription: POS_VALUE_DESCRIPTIONS[posString],
          selected: FRAGMENT_IDS.stem === selectedFragment,
        },
      ].filter((obj) => obj.attributeValue);
    } else {
      return [
        {
          attributeName: "language",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue:
            selectedWord?.word?.language?.name === "Spanish"
              ? null
              : selectedWord?.word?.language?.name,
          attributeNameDescription:
            "is the system of communication to which the word belongs",
          attributeValueDescription:
            LANGUAGE_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.word?.language?.name)
            ],
          selected: false,
        },
        {
          attributeName: "modern equivalent",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue: selectedWord?.word?.modernEquivalent,
          attributeNameDescription: "is a more modern version of the word",
          attributeValueDescription: null,
          selected: false,
        },
        {
          attributeName: "lemma",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue: selectedWord?.word?.lemma,
          attributeNameDescription: "is a word's base form",
          attributeValueDescription: null,
          selected: false,
        },
        {
          attributeName: "expected word",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue: selectedWord?.word?.expectedWord,
          attributeNameDescription:
            "is the form that the word would take were it not irregular",
          attributeValueDescription: null,
          selected: false,
        },
        {
          attributeName: "contraction",
          attributeColor: null,
          attributeValueColor: `attributeValue known`,
          attributeValue: contractionString,
          attributeNameDescription:
            "is the two or more words combined to create a new word",
          attributeValueDescription: null,
          selected: false,
        },
        {
          attributeName: "part of speech",
          attributeColor: `stem ${stemVocabularyState}`,
          attributeValueColor: `attributeValue ${stemVocabularyState}`,
          attributeValue: posArr,
          attributeNameDescription: "indicates a word's function",
          attributeValueDescription: null,
          selected: FRAGMENT_IDS.stem === selectedFragment,
        },
        {
          attributeName: "pronoun type",
          attributeColor: `stem ${stemVocabularyState}`,
          attributeValueColor: `attributeValue ${stemVocabularyState}`,
          attributeValue: selectedWord?.pronounType,
          attributeNameDescription:
            "is a more specific indication of a pronouns usage in a sentence",
          attributeValueDescription:
            PRONOUN_TYPE_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.pronounType)
            ],
          selected: FRAGMENT_IDS.stem === selectedFragment,
        },
        {
          attributeName: "pronoun function",
          attributeColor: `stem ${stemVocabularyState}`,
          attributeValueColor: `attributeValue ${stemVocabularyState}`,
          attributeValue: selectedWord?.pronounFunction,
          attributeNameDescription: "indicates the role of the pronoun",
          attributeValueDescription:
            PRONOUN_FUNCTION_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.pronounFunction)
            ],
          selected: FRAGMENT_IDS.stem === selectedFragment,
        },
        {
          attributeName: "verb type",
          attributeColor: `stem ${stemVocabularyState}`,
          attributeValueColor: `attributeValue ${stemVocabularyState}`,
          attributeValue: selectedWord?.verbType,
          attributeNameDescription:
            "determines which set of endings a regular verb will take",
          attributeValueDescription:
            VERB_TYPE_VALUE_DESCRIPTIONS[
              selectedWord?.verbType?.replace(/-/g, "")
            ],
          selected: FRAGMENT_IDS.stem === selectedFragment,
        },
        {
          attributeName: "form",
          attributeColor: `${formContributingFragment} ${formColor}`,
          attributeValueColor: `attributeValue ${formColor}`,
          attributeValue: selectedWord?.form,
          attributeNameDescription: "is the broadest category of verb",
          attributeValueDescription:
            FORM_VALUE_DESCRIPTIONS[toCamelCase(selectedWord?.form)],
          selected: FRAGMENT_IDS[formContributingFragment] === selectedFragment,
        },
        {
          attributeName: "mood",
          attributeColor: `${moodContributingFragment} ${moodColor}`,
          attributeValueColor: `attributeValue ${moodColor}`,
          attributeValue: selectedWord?.mood,
          attributeNameDescription:
            "is the speaker's attitude in the use of the verb",
          attributeValueDescription:
            MOOD_VALUE_DESCRIPTIONS[toCamelCase(selectedWord?.mood)],
          selected: FRAGMENT_IDS[moodContributingFragment] === selectedFragment,
        },
        {
          attributeName: "tense",
          attributeColor: `${tenseContributingFragment} ${tenseColor}`,
          attributeValueColor: `attributeValue ${tenseColor}`,
          attributeValue: selectedWord?.tense,
          attributeNameDescription: "indicates the time aspect of the verb",
          attributeValueDescription:
            TENSE_VALUE_DESCRIPTIONS[toCamelCase(selectedWord?.tense)],
          selected:
            FRAGMENT_IDS[tenseContributingFragment] === selectedFragment,
        },
        {
          attributeName: "person",
          attributeColor: `${personContributingFragment} ${personColor}`,
          attributeValueColor: `attributeValue ${personColor}`,
          attributeValue: selectedWord?.person,
          attributeNameDescription: "indicates the perspective of the subject",
          attributeValueDescription:
            PERSON_VALUE_DESCRIPTIONS[toTextualPerson(selectedWord?.person)],
          selected:
            FRAGMENT_IDS[personContributingFragment] === selectedFragment,
        },
        {
          attributeName: "gender",
          attributeColor: `${genderContributingFragment} ${genderColor}`,
          attributeValueColor: `attributeValue ${genderColor}`,
          attributeValue: selectedWord?.gender,
          attributeNameDescription:
            "is a classification of words which affects agreement with others",
          attributeValueDescription:
            GENDER_VALUE_DESCRIPTIONS[toCamelCase(selectedWord?.gender)],
          selected:
            FRAGMENT_IDS[genderContributingFragment] === selectedFragment,
        },
        {
          attributeName: "number",
          attributeColor: `${numberContributingFragment} ${numberColor}`,
          attributeValueColor: `attributeValue ${numberColor}`,
          attributeValue: selectedWord?.number,
          attributeNameDescription: "Indicates a word's quantity",
          attributeValueDescription:
            NUMBER_VALUE_DESCRIPTIONS[toCamelCase(selectedWord?.number)],
          selected:
            FRAGMENT_IDS[numberContributingFragment] === selectedFragment,
        },
        {
          attributeName: "first clitic function",
          attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
          attributeValueColor: `attributeValue ${clitic1Color}`,
          attributeValue: selectedWord?.clitic1Function,
          attributeNameDescription:
            "is the first clitic's role as it relates to the verb",
          attributeValueDescription:
            CLITIC_FUNCTION_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic1Function)
            ],
          selected:
            FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "first clitic person",
          attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
          attributeValueColor: `attributeValue ${clitic1Color}`,
          attributeValue: selectedWord?.clitic1Person,
          attributeNameDescription:
            "is the perspective of the noun represented by the first clitic",
          attributeValueDescription:
            CLITIC_PERSON_VALUE_DESCRIPTIONS[
              toTextualPerson(selectedWord?.clitic1Person)
            ],
          selected:
            FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "first clitic gender",
          attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
          attributeValueColor: `attributeValue ${clitic1Color}`,
          attributeValue: selectedWord?.clitic1Gender,
          attributeNameDescription:
            "is a classification of the noun represented by the first clitic which affects agreement with other words",
          attributeValueDescription:
            CLITIC_GENDER_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic1Gender)
            ],
          selected:
            FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "first clitic number",
          attributeColor: `${clitic1ContributingFragment} ${clitic1Color}`,
          attributeValueColor: `attributeValue ${clitic1Color}`,
          attributeValue: selectedWord?.clitic1Number,
          attributeNameDescription:
            "is the numerical composition of the noun represented by the first clitic",
          attributeValueDescription:
            CLITIC_NUMBER_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic1Number)
            ],
          selected:
            FRAGMENT_IDS[clitic1ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "second clitic function",
          attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
          attributeValueColor: `attributeValue ${clitic2Color}`,
          attributeValue: selectedWord?.clitic2Function,
          attributeNameDescription:
            "is the second clitic's role as it relates to the verb",
          attributeValueDescription:
            CLITIC_FUNCTION_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic2Function)
            ],
          selected:
            FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "second clitic person",
          attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
          attributeValueColor: `attributeValue ${clitic2Color}`,
          attributeValue: selectedWord?.clitic2Person,
          attributeNameDescription:
            "is the perspective of the noun represented by the second clitic",
          attributeValueDescription:
            CLITIC_PERSON_VALUE_DESCRIPTIONS[
              toTextualPerson(selectedWord?.clitic2Person)
            ],
          selected:
            FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "second clitic gender",
          attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
          attributeValueColor: `attributeValue ${clitic2Color}`,
          attributeValue: selectedWord?.clitic2Gender,
          attributeNameDescription:
            "is a classification of the noun represented by the first clitic which affects agreement with other words",
          attributeValueDescription:
            CLITIC_GENDER_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic2Gender)
            ],
          selected:
            FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
        },
        {
          attributeName: "second clitic number",
          attributeColor: `${clitic2ContributingFragment} ${clitic2Color}`,
          attributeValueColor: `attributeValue ${clitic2Color}`,
          attributeValue: selectedWord?.clitic2Number,
          attributeNameDescription:
            "is the numerical composition of the noun represented by the first clitic",
          attributeValueDescription:
            CLITIC_NUMBER_VALUE_DESCRIPTIONS[
              toCamelCase(selectedWord?.clitic2Number)
            ],
          selected:
            FRAGMENT_IDS[clitic2ContributingFragment] === selectedFragment,
        },
      ].filter((obj) => obj.attributeValue);
    }
  }
};
