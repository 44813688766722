import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
import WordInstance from "./WordInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";

import styles from "./Target.module.css";

const Target = () => {
  const {
    bookId,
    chapter,
    targetSentenceRef,
    currentWordInstances,
    beginsParagraph,
    selectedWord,
    selectedFragment,
    getVocabularyByFragmentId,
    currentSentenceId,
    knownCountUp,
    knownCountDown,
    transitionTime,
    isHideGuide,
  } = useContext(SentenceContext);

  const [displayedSentenceId, setDisplayedSentenceId] =
    useState(currentSentenceId);
  const [transitionStage, setTransitionStage] = useState("fadeIn");

  const [hasNoNewFragments, setHasNoNewFragments] = useState(true);
  const [hasNoSeenFragments, setHasNoSeenFragments] = useState(true);
  const [hasNoKnownFragments, setHasNoKnownFragments] = useState(true);

  const displayedWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, displayedSentenceId)
  );

  // Fade out the old sentence, then switch it, then fade in items individually
  useEffect(() => {
    if (currentSentenceId !== displayedSentenceId) {
      setTimeout(() => {
        setTransitionStage("fadeOut");
      }, 50);

      const timeoutId = setTimeout(() => {
        setDisplayedSentenceId(currentSentenceId);
        setTransitionStage("fadeIn"); // We'll no longer apply .fadeIn to the container, but we keep the state if needed.
      }, transitionTime);

      setTimeout(() => {
        setTransitionStage("rest");
      }, transitionTime + 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentSentenceId, displayedSentenceId]);

  const olRef = useRef(null);
  const [olLeftOffset, setOlLeftOffset] = useState(null);
  const [olRightOffset, setOlRightOffset] = useState(null);

  const updateOffsets = () => {
    if (olRef.current) {
      const rect = olRef.current.getBoundingClientRect();
      setOlLeftOffset(rect.left);
      setOlRightOffset(rect.right);
    }
  };

  useEffect(() => {
    updateOffsets();
    window.addEventListener("resize", updateOffsets);
    return () => {
      window.removeEventListener("resize", updateOffsets);
    };
  }, [displayedSentenceId]);

  useEffect(() => {
    if (olRef.current) {
      olRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [displayedSentenceId]);

  const scrollRef = useRef(null);
  const [showIndicator, setShowIndicator] = useState(true);

  useEffect(() => {
    const scrollElement = olRef.current;
    if (!scrollElement) return;

    const handleScroll = () => {
      // Determine if we are at (or nearly at) the bottom.
      const isAtBottom =
        scrollElement.scrollTop + scrollElement.clientHeight >=
        scrollElement.scrollHeight - 1;
      setShowIndicator(!isAtBottom);
    };

    // Initialize the indicator state.
    handleScroll();

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, []);

  const backgroundImageUrl = `url('/img/lesson-covers/don-quixote/chapter${chapter}.jpeg')`;

  const content = (
    <ol
      id="target"
      ref={olRef}
      // Only fade out the entire <ol> if needed; do not fade it in here
      className={`${styles.sentence} ${
        transitionStage === "fadeOut" ? styles.fadeOut : ""
      } ${isHideGuide ? styles.showSentence : ""}`}
    >
      {displayedWordInstances.map((wordInstance, index) => {
        const {
          _id: wordInstanceId,
          text: wordInstanceText,
          word,
          translation,
          stem,
          ending1,
          ending2,
          ending3,
          leadingPunctuation,
          trailingPunctuation,
        } = wordInstance;

        let mutableWordInstanceText = wordInstanceText;
        const fragments = [stem, ending1, ending2, ending3].filter(
          (obj) => obj !== null
        );
        const slices = fragments.map((fragment, sliceIndex) => {
          const textLength = fragment.text.length;
          const textSlice = mutableWordInstanceText.slice(0, textLength);
          mutableWordInstanceText = mutableWordInstanceText.slice(textLength);
          const vocabulary = getVocabularyByFragmentId(fragment._id);

          return {
            fragmentId: fragment._id,
            text: textSlice,
            type: sliceIndex ? `ending${sliceIndex}` : "stem",
            vocabulary: vocabulary,
            isSelectedFragment: fragment._id === selectedFragment,
          };
        });

        const isSelectedWord = wordInstanceId === selectedWord;

        // We use an inline style for animation-delay and a .fadeInItem CSS class
        // so items appear sequentially.
        const itemStyle = {
          animationDelay: `${0.04 * index}s`,
        };

        return (
          <li
            key={wordInstanceId}
            className={styles.fadeInItem}
            style={itemStyle}
          >
            <WordInstance
              wordInstanceId={wordInstanceId}
              translation={translation}
              leadingPunctuation={leadingPunctuation}
              trailingPunctuation={trailingPunctuation}
              isSelectedWord={isSelectedWord}
              slices={slices}
              modernEquivalent={word.modernEquivalent}
              olLeftOffset={olLeftOffset}
              olRightOffset={olRightOffset}
              indent={beginsParagraph && index === 0}
              transitionStage={transitionStage}
              hasNoNewFragments={hasNoNewFragments}
              hasNoSeenFragments={hasNoSeenFragments}
              hasNoKnownFragments={hasNoKnownFragments}
              instanceIndex={index}
            />
          </li>
        );
      })}
      <li className={styles.spacer}></li>
    </ol>
  );

  return (
    <div ref={targetSentenceRef} className={styles.gridTarget}>
      <div
        className={styles.sentenceContainer}
        style={{ "--background-image": backgroundImageUrl }}
      >
        {content}
        {/* <Guide /> */}
      </div>
      {/* {showIndicator && (
        <div className={styles.scrollIndicator}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )} */}
    </div>
  );
};

const memoizedTarget = memo(Target);
export default memoizedTarget;
